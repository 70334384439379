<template>
  <b-modal
    :title="$t('GERAL.ESQUECEU_SENHA')"
    header-bg-variant="light"
    ref="modal-senha"
    size="md"
    hide-footer
    @hidden="fecharModal()"
  >
    <b-card-text>
      <input-text
        ref="email"
        type="email"
        v-model="form.email"
        :label="$t('GERAL.EMAIL')"
        :placeholder="$t('GERAL.EMAIL')"
        required
      />

      <b-button
        variant="primary"
        size="lg"
        block
        class="mt-4"
        @click="enviarEmail"
        >{{ $t("GERAL.ALTERAR_SENHA") }}</b-button
      >
    </b-card-text>
  </b-modal>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import mensagem from "@/common/utils/mensagem";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import UsuarioService from "@/common/services/usuario/usuario.service";
export default {
  name: "ModalEsqueceuSenha",
  data() {
    return {
      form: {
        email: null,
      },
    };
  },
  components: {
    InputText,
  },
  props: {
    mostrarModal: { type: Boolean, default: false },
  },
  watch: {
    mostrarModal(valor) {
      if (valor) this.$refs["modal-senha"].show();
      else this.$refs["modal-senha"].hide();
    },
  },
  methods: {
    fecharModal() {
      this.$emit("fecharModal");
    },
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.email.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    enviarEmail() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const form = this.form;
      UsuarioService.esqueceuSenha(form)
        .then(() => {
          mensagem.sucesso(
            this.$t("GERAL.SUCCESSO"),
            this.$t("GERAL.EMAIL_ENVIADO")
          );

          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
