<template>
    <div id="login" style="min-height: calc(100vh - 20px)" tag="section">
        <b-row class="justify-content-center no-gutters">
            <b-col lg="12" class="d-flex align-items-center">
                <b-card no-body class="w-100">
                    <b-row class="no-gutters" style="min-height: 100vh">
                        <b-col
                            lg="6"
                            class="bg-cej d-md-flex align-items-center justify-content-center"
                        >
                            <div class="d-flex align-items-center">
                                <div>
                                    <b-row class="justify-content-center">
                                        <b-col cols="12" lg="12" xl="12">
                                            <div>
                                                <h2
                                                    class="display-5 text-white fw-medium"
                                                >
                                                    Portal do Aluno
                                                </h2>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                        <b-col
                            lg="6"
                            class="d-md-flex align-items-center justify-content-center"
                        >
                            <div class="d-flex align-items-center w-100">
                                <div class="p-5 w-100">
                                    <b-row class="justify-content-center">
                                        <b-col cols="12" lg="9" xl="6">
                                            <div class="d-flex mb-4">
                                                <img
                                                    class="m-auto login__logo-img"
                                                    src="@/assets/images/logo.png"
                                                    width="300"
                                                />
                                            </div>
                                            <h2 class="font-weight-bold mt-4">
                                                {{ $t('GERAL.LOGIN') }}
                                            </h2>
                                            <input-text
                                                ref="email"
                                                v-model="form.email"
                                                noLabel
                                                :label="$t('GERAL.EMAIL')"
                                                :placeholder="$t('GERAL.EMAIL')"
                                                required
                                            />
                                            <input-text
                                                ref="senha"
                                                v-model="form.senha"
                                                noLabel
                                                type="password"
                                                :label="$t('GERAL.SENHA')"
                                                :placeholder="$t('GERAL.SENHA')"
                                                required
                                            />
                                            <b-button
                                                variant="primary"
                                                size="lg"
                                                block
                                                class="mt-4"
                                                @click="login"
                                                >{{
                                                    $t('GERAL.LOGIN')
                                                }}</b-button
                                            >
                                            <div class="ml-auto mt-2">
                                                <a
                                                    @click="abrirModal"
                                                    href="javascript:void(0)"
                                                    class="link"
                                                    >{{
                                                        $t(
                                                            'GERAL.ESQUECEU_SENHA'
                                                        )
                                                    }}</a
                                                >
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <modal-esqueceu-senha
            :mostrarModal="mostrarModal"
            @fecharModal="fecharModal()"
        />
    </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

import InputText from '@/components/inputs/InputText';
import { LOGIN } from '@/store/autenticacao.module';
import ModalEsqueceuSenha from './components/ModalEsqueceuSenha';

export default {
    name: 'Login',
    components: {
        InputText,
        ModalEsqueceuSenha,
    },
    data() {
        return {
            form: {
                email: process.env.VUE_APP_EMAIL || '',
                senha: process.env.VUE_APP_PASS || '',
            },
            mostrarModal: false,
        };
    },
    methods: {
        validarFormulario() {
            let arValidation = [];
            arValidation.push(this.$refs.email.valid());
            arValidation.push(this.$refs.senha.valid());

            return arValidation.filter((elem) => elem == false).length == 0;
        },
        login() {
            if (!this.validarFormulario()) return;
            this.$store.dispatch(START_LOADING);
            const form = this.form;
            this.$store
                .dispatch(LOGIN, {
                    ...form,
                    senha: helpers.Encriptar(form.senha),
                })
                .then(() => this.$router.push({ name: 'dashboard' }))
                .catch((err) => {
                    err.response.data.errors.forEach((element) => {
                        element.tipoNotificacao === 'Erro' ||
                        element.tipoNotificacao === null
                            ? mensagem.erro(
                                  this.$t('GERAL.ERRO'),
                                  element.mensagem
                              )
                            : mensagem.warning(
                                  this.$t('GERAL.AVISO'),
                                  element.mensagem
                              );
                    });
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        abrirModal() {
            this.mostrarModal = true;
        },
        fecharModal() {
            this.mostrarModal = false;
        },
    },
};
</script>
<style>
.login__logo-img {
    width: 250px;
    max-width: 100%;
}
</style>
